<template lang="pug">
	section#novoProjeto
		.wrapper
			.left
				h2
					|Para cada
					span
						br
					|projeto, um novo olhar sustentável.
				.dots
					i.first
					i.second
					i.third
				p
					|A c.alpha home possui projetos
					span
						br
					|sustentáveis que transformam mais que
					span
						br
					|lugares, transformam vidas.
			.right
				p
					|Para quem busca vivenciar um ambiente inteligente, criamos ecossistemas tecnológicos que
					|conversam entre si, com você e com o mundo a sua volta. De empresas e fundos de investimentos à
					|segunda moradia e family offices, a c.alpha detecta oportunidades de mercado para criar espaços sustentáveis,
					|levando mais qualidade de vida para residências e elevando nas corporações os tão requisitados pontos de ESG
					|(Pt: Governança ambiental, social e corporativa).

</template>

<script>
export default {
    name: "section-novo-projeto",
	mounted() {
		const element = document.getElementById('novoProjeto');
		let options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}
		const iObserverNP = new IntersectionObserver(
			entries => {
				if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.40) 
					element.classList.add('animation')
			},
			options
		);
		iObserverNP.observe(element)

	}
}
</script>

<style lang="stylus" scoped src="./NovoProjeto.styl"></style>
