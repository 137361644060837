<template lang="pug">
	section#conectado
		.wrapper
			p
				|Faça da sua empresa ou casa um
				|lugar conectado com o meio
				|ambiente, como todo lugar
				|inteligente deve ser.
</template>

<script>
export default {
    name: "section-conectado",
	mounted() {
		const element = document.getElementById('conectado');
		let options = {
			threshold: [0, 0.25, 0.3, 0.5, 0.75, 1]
		}
		const iObserverCon = new IntersectionObserver(
			entries => {
				if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.3) 
					element.classList.add('animation')
			},
			options
		);
		iObserverCon.observe(element)

	}
}
</script>

<style lang="stylus" scoped src="./Conectado.styl"></style>
