<template lang="pug">
	section#contato
		.wrapper
			.left
				h2
					|Vamos transformar 
					span juntos?
				p
					|Preencha o formulário ao lado. Em breve, um de nossos especialistas entrará em contato com você.
			.right
				form(@submit.prevent="send")
					.input(:class="{'error': errors.nome}")
						input(type="text" placeholder="Nome", v-model="form.fields.nome")
						span(v-if="errors.nome") {{errors.nome}}
						//- span erro
					.input(:class="{'error': errors.email}")
						input(type="text" placeholder="E-mail", v-model="form.email")
						span(v-if="errors.email") {{errors.email}}
					.input(:class="{'error': errors.telefone}")
						input(type="text" placeholder="Telefone", v-model="form.fields.telefone", v-mask="['(##) ####-####', '(##) #####-####']")
						span(v-if="errors.telefone") {{errors.telefone}}
					.input(:class="{'error': errors.cidade}")
						input(type="text" placeholder="Cidade", v-model="form.fields.cidade")
						span(v-if="errors.cidade") {{errors.cidade}}
					.input(:class="{'error': errors.estado}")
						input(type="text" placeholder="Estado", v-model="form.fields.estado")
						span(v-if="errors.estado") {{errors.estado}}
					.input(:class="{'error': errors.projeto}")
						input(type="text" placeholder="Busco projeto para", v-model="form.fields.projeto")
						span(v-if="errors.projeto") {{errors.projeto}}
					//- .select
						select(v-model="form.fields.projeto", :class="{'error': errors.projeto}")
							option(value='', disabled) Busco projeto para:
							option(value='teste') Teste
						span(v-if="errors.projeto") {{errors.projeto}}
					span *Dados protegidos pela LGPD (Lei Geral de Proteção de Dados) Lei 13.709/18.
					button(type="submit", v-html="buttonText", :disabled="buttonDisabled")
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
	name: "section-contato",
	directives: {
		mask
	},
	data() {
		return {
			form: {
				subject: 'Contato',
				email: '',
				origin: 'Contato LP',
				client: 'C.alpha Home',
				fields: {
					nome: '',
					telefone: '',
					cidade: '',
					estado: '',
					projeto: '',
				},
				requiredFields: ['nome', 'telefone', 'cidade', 'estado', 'projeto'],
				sendto: process.env.VUE_APP_SENDTO,
				sendfrom: process.env.VUE_APP_SENDFROM,
			},
			buttonText: 'Enviar',
			buttonDisabled: false,
			errors: {}
		}
	},
	mounted() {
		const element = document.getElementById('contato');
		let options = {
			threshold: [0, 0.25, 0.3, 0.5, 0.75, 1]
		}
		const iObserverForm = new IntersectionObserver(
			entries => {
				if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.2) 
					element.classList.add('animation')
			},
			options
		);
		iObserverForm.observe(element)
	},
	methods: {
		send() {
			this.buttonDisabled = true
			this.buttonText = "Enviando..."
			this.$axios.post('/send', this.form)
				.then(response => {
					this.buttonText = "Enviado com sucesso!"
					this.form = {
						subject: 'Contato',
						email: '',
						origin: 'Contato LP',
						client: 'C.alpha Home',
						fields: {
							nome: '',
							telefone: '',
							cidade: '',
							estado: '',
							projeto: '',
						},
						requiredFields: ['nome', 'telefone', 'cidade', 'estado', 'projeto'],
						sendto: process.env.VUE_APP_SENDTO,
						sendfrom: process.env.VUE_APP_SENDFROM,
					}
				})
				.catch(error => {
                    this.errors = error.response.data
                    this.buttonText = "Ocorreu um erro!"
                })
				.finally(() => {
                    setTimeout(() => {
                        this.buttonDisabled = false
                        this.buttonText = "Enviar"
                    }, 5000);
                })
		}
	}
}
</script>

<style lang="stylus" scoped src="./Contato.styl"></style>
