<template lang="pug">
	section#banner
		h1
			|C.alpha Home
			img(:src="require('@images/logo-negativo.png')", alt="C.alpha Home")
		p
			|O começo da sua
			span nova era.
</template>

<script>
export default {
	name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
