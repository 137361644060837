<template lang="pug">
	section#tripe
		ul
			li(v-for="item, index in tripe", :class="{'invert': item.invert}" :id="item.id")
				.img
					img(:src="item.image", :alt="item.title")
					h2 {{item.title}}
				.text
					p(v-html="item.text")
					.dots
						i(v-for="i, dotIndex in 3", :class="{'active': index == dotIndex }")
</template>

<script>
export default {
    name: "section-tripe",
	data() {
		return {
			tripe: [
				{
					id: 'missao',
					title: 'Missão',
					text: 'Levar a sustentabilidade aos mais diversos ambientes do mercado nacional, de forma inteligente e acessível.',
					image: require('@images/missao_01.jpg'),
					invert: false
				},
				{
					id: 'visao',
					title: 'Visão',
					text: 'Ser a marca referência em projetos sustentáveis no Sul do país.',
					image: require('@images/visao_01.jpg'),
					invert: true,
				},
				{
					id: 'valores',
					title: 'Valores',
					text: 'Sustentabilidade<br>Inovação<br>Respeito<br>Ética<br>Fidelidade',
					image: require('@images/valores_01.jpg'),
					invert: false,
				},
			]
		}
	},
	mounted() {
		const missao = document.getElementById('missao');
		const visao = document.getElementById('visao');
		const valores = document.getElementById('valores');
		let options = {
			threshold: [0, 0.25, 0.3, 0.5, 0.75, 1]
		}
		const iObserverForm = new IntersectionObserver(
			entries => {
				if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.3) 
					entries[0].target.classList.add('animation')
			},
			options
		);
		iObserverForm.observe(missao)
		iObserverForm.observe(visao)
		iObserverForm.observe(valores)
	}
}
</script>

<style lang="stylus" scoped src="./Tripe.styl"></style>
