<template lang="pug">
	main#home
		Banner
		NovoProjeto
		Conectado
		Contato
		Tripe
</template>

<script>
import Banner from '@sections/Home/Banner/Banner'
import NovoProjeto from '@sections/Home/NovoProjeto/NovoProjeto'
import Conectado from '@sections/Home/Conectado/Conectado'
import Contato from '@sections/Home/Contato/Contato'
import Tripe from '@sections/Home/Tripe/Tripe'

export default {
	name: 'view-home',
	components: {
		Banner,
		NovoProjeto,
		Conectado,
		Contato,
		Tripe
	},
	metaInfo() {
		return {
			title: 'C.Alpha Home',
			meta: [
				{ name: 'description', content: 'O começo da sua nova era.' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>
